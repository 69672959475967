<template>
  <div class="content">
    <div class="main-content" ref="containerRef">
      <a-spin tip="Loading..." :spinning="loading">
        <a-row>
          <a-col :sm="18" :lg="14" :xxl="10">
            <a-form style="padding-bottom: 80px">
              <e-input
                title="菜品名称"
                :validate="validateInfos.name"
                v-model:value="modelRef.name"
              />

              <e-cascader
                title="菜品类型"
                :options="cateOptions"
                :validate="validateInfos.dishTypeId"
                v-model:value="parentIds"
                @ok="changeDishType"
              />

              <e-input-upload title="菜品图片" v-model:value="modelRef.pic" />
              <a-row style="padding-left: 2px">
                <a-col :span="7" :offset="1">
                  <e-input-number
                    title="成本价"
                    v-model:value="modelRef.price"
                  />
                </a-col>
                <a-col :span="8">
                  <e-input-number
                    title="售价"
                    v-model:value="modelRef.sellingPrice"
                    @ok="changePrice"
                  />
                </a-col>
                <a-col :span="8">
                  <e-input-number
                    title="毛利率/%"
                    v-model:value="modelRef.grossMargin"
                    @ok="changePrice"
                  />
                </a-col>
              </a-row>

              <a-row style="padding-left: 2px">
                <a-col :span="7" :offset="1">
                  <e-input
                    :labelCol="10"
                    :valueCol="14"
                    title="单位"
                    v-model:value="modelRef.unit"
                  />
                </a-col>
                <a-col :span="8">
                  <e-input
                    title="味型"
                    :labelCol="10"
                    :valueCol="14"
                    v-model:value="modelRef.tasteType"
                  />
                </a-col>
                <a-col :span="8">
                  <e-input-number
                    title="推荐顺序"
                    v-model:value="modelRef.recommendedSortNum"
                  />
                </a-col>
              </a-row>

              <a-row style="padding-left: 2px">
                <a-col :span="7" :offset="1">
                  <e-input
                    :labelCol="10"
                    :valueCol="14"
                    title="标签"
                    v-model:value="modelRef.label"
                  />
                </a-col>
                <a-col :span="16">
                  <e-input-number
                    title="销量"
                    :labelCol="6"
                    :valueCol="18"
                    v-model:value="modelRef.saleCount"
                  />
                </a-col>
              </a-row>

              <e-input
                title="介绍文字"
                v-model:value="modelRef.brief"
              />

              <a-row style="padding-left: 2px">
                <a-col :span="5" :offset="1">
                  <e-switch
                    :labelCol="14"
                    :valueCol="10"
                    title="是否上架"
                    v-model:value="modelRef.isOn"
                  />
                </a-col>
                <a-col :span="5">
                  <e-switch
                    :labelCol="14"
                    :valueCol="10"
                    title="是否人均"
                    v-model:value="modelRef.isPersonal"
                  />
                </a-col>
              </a-row>

              <a-row>
                <a-col :span="4">
                  <div class="label-title" style="font-weight: 800">主辅料</div>
                </a-col>
                <a-col :span="20">
                  <div class="fields">
                    <a-table
                      :columns="columns"
                      :data-source="modelRef.dishGoodsItems"
                      :pagination="false"
                      :loading="loading2"
                      :row-key="(index) => index"
                      size="small"
                      :scroll="{ y: 200, x: true }"
                    >
                      <template #bodyCell="{ column, index, record }">
                        <template v-if="column.key === 'index'">
                          {{ index + 1 }}
                        </template>

                        <template v-if="column.key === 'count'">
                          <a-input-number v-model:value="record.count" />
                        </template>

                        <template v-if="column.key === 'unit'">
                          <s-select
                            :margin="0"
                            v-model:value="record.unit"
                            :options="unitOptions"
                          />
                        </template>

                        <template v-if="column.key === 'action'">
                          <a-tooltip>
                            <template #title>删除</template>
                            <a @click.stop="delFields(index)">
                              <DeleteOutlined />
                            </a>
                          </a-tooltip>
                        </template>
                      </template>
                    </a-table>
                    <div
                      style="
                        padding: 5px;
                        display: flex;
                        justify-content: space-between;
                      "
                    >
                      <div>
                        <a-button
                          type="primary"
                          @click="$refs.goods.open()"
                          size="small"
                        >
                          添加商品
                        </a-button>
                      </div>
                      <a-button
                        type="primary"
                        danger
                        @click="clearGoods"
                        size="small"
                      >
                        清空
                      </a-button>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-form>
          </a-col>
        </a-row>
      </a-spin>

      <div class="form-footer">
        <a-button
          @click="onSubmitFun"
          :loading="loading"
          size="large"
          type="primary"
        >
          提交
        </a-button>
        <a-button @click="fetchData" size="large" style="margin-left: 15px">
          重置
        </a-button>
      </div>

      <e-modal
        :visible="visible"
        :title="title"
        :width="600"
        @close="onClose"
        @ok="onAddFieldFun"
      >
        <a-form>
          <SearchGoods v-model:value="modelRef2.goodsId" @ok="chooseGoods" />

          <a-row style="padding-left: 2px">
            <a-col :span="7" :offset="1">
              <e-input-number title="数量" v-model:value="modelRef2.count" />
            </a-col>
            <a-col :span="10">
              <!-- <e-input title="单位" :labelCol="8" :valueCol="14" v-model:value="modelRef2.unit" /> -->
              <!-- <e-select
                title="单位"
                :labelCol="12"
                :valueCol="12"
                v-model:value="modelRef2.unit"
                :options="units"
              /> -->
            </a-col>
          </a-row>
        </a-form>
      </e-modal>
    </div>

    <Goods ref="goods" @ok="onPushGoods" />
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRaw, createVNode } from 'vue'
import {
  ExclamationCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue'
import { DishClass } from '@/apis/dish'
import { GoodsClass } from '@/apis/goods'
import { Form } from 'ant-design-vue'

import form from '@/mixins/form'
import SearchGoods from '@/components/form/SearchGoods'
import Goods from '@/components/forms/dish/Goods'
import { dataToCascader, getParentIdArray } from '@/utils'

const useForm = Form.useForm
const api = new DishClass()
const goods = new GoodsClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: { DeleteOutlined, SearchGoods, Goods },
  setup () {
    const loading = ref(false)
    const loading2 = ref(false)
    const id = ref('')
    const index = ref(-1)
    const fields = ref([])
    const isChanged = ref(false)

    const parentIds = ref([])
    const cateOptions = ref([])

    const modelRef2 = ref({
      goodsId: '',
      goodsTitle: '',
      goodsUnit: '',
      goodsPrice: 0,
      unit: '',
      count: 1
    })

    const visible = ref(false)
    const title = ref('')
    const onClose = () => {
      modelRef2.value = {
        goodsId: '',
        goodsTitle: '',
        goodsUnit: '',
        goodsPrice: 0,
        unit: '1',
        count: 1
      }
      index.value = -1
      visible.value = false
    }

    const modelRef = reactive(api.modelRef)
    const rulesRef = reactive(api.rulesRef)
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const chooseGoods = (e) => {
      console.log(e)
      modelRef2.value.goodsTitle = e.title
      modelRef2.value.goodsPrice = e.stockPrice
      modelRef2.value.goodsUnit = e.unit
    }

    const clearGoods = () => {
      modelRef.dishGoodsItems = []
    }

    const onPushGoods = (e) => {
      loading2.value = true
      goods.getList(e).then((resp) => {
        resp.map((e) => {
          modelRef.dishGoodsItems.push({
            goodsId: e.id,
            goodsTitle: e.title,
            goodsUnit: e.goodsUnit,
            goodsPrice: e.stockPrice,
            count: 1,
            unit: '克'
          })
        })
        loading2.value = false
      })
    }

    return {
      loading,
      id,
      fields,
      visible,
      title,
      onClose,
      chooseGoods,
      index,
      isChanged,

      parentIds,
      cateOptions,

      modelRef,
      validateInfos,
      resetFields,
      validate,
      clearGoods,
      modelRef2,
      onPushGoods,
      loading2,
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 50
        },
        {
          title: '商品名称',
          key: 'goodsTitle',
          dataIndex: 'goodsTitle'
        },
        {
          title: '数量',
          key: 'count',
          dataIndex: 'count',
          width: 100
        },
        {
          title: '单位',
          key: 'unit',
          dataIndex: 'unit',
          width: 100
        },
        {
          title: '删除',
          key: 'action',
          dataIndex: 'action',
          width: 45
        }
      ],

      unitOptions: [
        {
          label: '克',
          value: '克'
        },
        {
          label: '个',
          value: '个'
        },
        {
          label: '瓶',
          value: '瓶'
        },
        {
          label: '张',
          value: '张'
        }
      ]
    }
  },

  created () {
    this.id = this.$route.params.id

    this.$watch('stockPrice', (e) => {
      if (!this.id || this.isChanged) {
        this.modelRef.price = e
      }
    })

    this.fetchData()
  },

  unmounted () {
    this.resetFields()
  },

  computed: {
    stockPrice () {
      const sp = this.modelRef.sellingPrice
      const ll = this.modelRef.grossMargin

      if (sp && ll) {
        return Number(((1 - ll / 100) * sp).toFixed(0))
      } else {
        return 0
      }
    }
  },

  methods: {
    changeDishType (e) {
      this.modelRef.dishTypeId = e[e.length - 1]
    },
    changePrice () {
      this.isChanged = true
      console.log(this.stockPrice)
      this.modelRef.price = this.stockPrice
    },
    echounit (unit) {
      const units = this.units
      const data = units.filter((e) => e.value === unit)
      return data[0].label
    },
    fetchData () {
      if (this.id) {
        this.loading = true
        api.detail(this.id).then((resp) => {
          this.modelRef.id = resp.id
          this.modelRef.name = resp.name
          this.modelRef.dishTypeId = resp.dishTypeId
          this.modelRef.pic = resp.pic
          this.modelRef.price = resp.price
          this.modelRef.sellingPrice = resp.sellingPrice
          this.modelRef.isOn = resp.isOn
          this.modelRef.unit = resp.unit
          this.modelRef.isPersonal = resp.isPersonal
          this.modelRef.type = resp.type
          this.modelRef.tasteType = resp.tasteType
          this.modelRef.recommendedSortNum = resp.recommendedSortNum
          this.modelRef.grossMargin = resp.grossMargin
          this.modelRef.brief = resp.brief
          this.modelRef.label = resp.label
          this.modelRef.dishGoodsItems = resp.dishGoods
          this.modelRef.saleCount = resp.saleCount
          this.loading = false

          this.getCategorys()
        })
      } else {
        this.getCategorys()
      }
    },

    async getCategorys () {
      const list =
        this.$store.state.data.ALL_DISHTYPES.length > 0
          ? this.$store.state.data.ALL_DISHTYPES
          : await this.$store.dispatch('GetAllDishTypes')

      this.cateOptions = dataToCascader(list, 0)

      if (this.id) {
        console.log(this.modelRef.dishTypeId)
        const parentIds = getParentIdArray(toRaw(list), this.modelRef.dishTypeId)
        parentIds.sort((a, b) => a - b)
        this.parentIds = parentIds.filter((x) => x > 0)
      }
    },

    onSubmitFun () {
      this.loading = true

      this.validate()
        .then(() => {
          const data = toRaw(this.modelRef)

          if (this.id) {
            this.updateFun(data)
          } else {
            this.createFun(data)
          }
        })
        .catch((e) => {
          this.loading = false
          e.errorFields.map((e) => {
            e.errors.map((x) => {
              this.$message.error(x)
            })
          })
        })
    },

    updateFun (data) {
      api
        .update(data)
        .then(() => {
          this.loading = false
          this.$message.success('编辑成功')
          this.$router.back()
        })
        .catch((err) => {
          this.loading = false
          this.$message.error('编辑失败')
          console.log('error', err)
        })
    },

    createFun (data) {
      delete data.id
      const _this = this
      api
        .create(data)
        .then(() => {
          this.loading = false
          _this.$confirm({
            title: '添加成功！',
            icon: createVNode(ExclamationCircleOutlined),
            content: createVNode(
              'div',
              {
                style: 'color:red;'
              },
              '是否继续添加？'
            ),
            onOk () {
              _this.resetFields()
              _this.modelRef.dishTypeId = data.dishTypeId
            },
            onCancel () {
              _this.$router.back()
            }
          })
        })
        .catch((err) => {
          this.loading = false
          this.$message.error('添加失败')
          console.log('error', err)
        })
    },

    addFields () {
      this.title = '添加商品'
      this.visible = true
    },
    onAddFieldFun () {
      const data = toRaw(this.modelRef2)

      if (!data.goodsTitle || !data.goodsId || data.count <= 0) return false
      if (this.index === -1) {
        this.modelRef.dishGoodsItems.push(data)
      }

      this.onClose()
    },
    delFields (index) {
      this.modelRef.dishGoodsItems.splice(index, 1)
    },
    getTableHeight () {
      this.height =
        this.$refs.content.offsetHeight - 112 - 30 - 32 - 80 - 30 - 50
      console.log(this.$refs.content.offsetHeight)
    }
  }
})
</script>

<style lang="less" scoped>
.form-footer {
  position: absolute;
  bottom: 9px;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.1);
  z-index: 10003;
  border-radius: 0 0 15px 15px;
  padding: 0 20px;
}
.fields {
  border: 1px solid #ccc;
}
</style>
